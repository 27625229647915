import '../styles/globals.css'
import React from 'react';
import Header from './Header'
import Main from './Main'
import Footer from './Footer'

function Form_Vote () {
  const widthTotal = "100%"

    return (
      <div className='app'>
        <Header/>
        <div className='teste'>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe29BQjaHETriob04jsYZhkfj0Pi4eQmKhUKuSV-iQUP4Y_Dw/viewform?embedded=true" width={widthTotal} height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Carregando…</iframe>
        </div>    
      </div>
    );
  }
  
  export default Form_Vote;