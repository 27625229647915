import { useNavigate } from 'react-router-dom';
import dragonfish from '../images/cdpamplogo.png'
import smallDragonfish from '../images/cdmobile.png'
import '../styles/main.css'

function Main() {
    const navigate = useNavigate();
    
    return (
        <div className='main-section'>
            <div className='dragon-fish-content'>
                <img className='dragon-fish' alt='' src={dragonfish}></img>
                <img className='small-dragon-fish' alt=''src={smallDragonfish}></img>
                <div>
                    <div className='button-align'>
                    <button 
                            onClick={() => navigate('/form')}
                            className='button-vote'>VOTA AQUI
                        </button>
                        <button 
                            onClick={() => navigate('/nominees')}
                            className='button-vote'>NOMEADOS
                        </button>
                    </div>
                    {/* <div>
                        <button className='button-nomenees'>NOMEADOS</button>
                    </div>
                    <div>
                        <button className='button-about'>SOBRE</button>
                    </div> */}
                </div>
            </div>
        </div>
    );
  }
  
  export default Main;