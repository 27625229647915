import pamplogo from '../images/pamp-logo.png'
import instagram from '../images/instagram.png'
import youtube from '../images/youtube.png'
import twitch from '../images/twitch.png'
import tiktok from '../images/tiktok.png'
import '../styles/globals.css'

function Header() {
    return (
      <div>
        <header>
          <div className="header-title">
            <a rel="noreferrer" href='https://www.pamp.pt'>
              <img className='pamp-logo' src={pamplogo} alt='pamp-logo'></img>
            </a>
          </div>
          {/* <div className='dropdown'>
            <button>hehe</button>
          </div> */}
          {/* <div className='login'>Login</div> */}
        </header>
        <div className='socials'>
          <a target="_blank" rel="noreferrer" href='https://www.instagram.com/premiosanimemangaportugal/'>
            <img className='socials-logo' src={instagram} alt='instagram'></img>
          </a>
          <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCFtlrLaz5WaGGWlWgXPaIqA'>
            <img className='socials-logo' src={youtube} alt='youtube'></img>
          </a>
          {/* <div>
            <img className='socials-logo' src={twitch} alt='twitch'></img>
          </div>
          <div>
            <img className='socials-logo' src={tiktok} alt='tiktok'></img>
          </div> */}
        </div>
      </div>
    );
  }
  
  export default Header;
  