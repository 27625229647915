import '../styles/globals.css'
import '../styles/nominees.css'
import React from 'react';
import Header from './Header'
import Main from './Main'
import Footer from './Footer'

import toReveal from '../images/toreveal.png'

/**Anime */
import DemonSlayer from '../images/pamp-nomeados/Demon Slayer.png'
import Jujutsu from '../images/pamp-nomeados/Jujutsu.png'
import Snk from '../images/pamp-nomeados/Shingeki no Kyojin.png'
import Trigun from '../images/pamp-nomeados/Trigun.png'
import DrStone from '../images/pamp-nomeados/Dr Stone.png'
import Frieren from '../images/pamp-nomeados/Frieren.png'
import Shangrila from '../images/pamp-nomeados/Shangri-la.png'
import Zom100 from '../images/pamp-nomeados/Zom 100.png'
import Insomniacs from '../images/pamp-nomeados/Insomniacs.png'
import MyHappyMarriage from '../images/pamp-nomeados/My Happy Marriage.png'
import ScottPilgrim from '../images/pamp-nomeados/Scott Pilgrim Takes Off.png'
import SkiptoLoafer from '../images/pamp-nomeados/Skip to Loafer.png'
import Yamada from '../images/pamp-nomeados/Yamada-kun.png'
import LinkClick from '../images/pamp-nomeados/Link Click.png'
import MeguminoDaigo from '../images/pamp-nomeados/Megumi no Daigo.png'
import Ooku from '../images/pamp-nomeados/Oooku.png'
import OshinoKo from '../images/pamp-nomeados/Oshi no Ko.png'
import Vinland from '../images/pamp-nomeados/Vinland Saga.png'
import DekiruNeko from '../images/pamp-nomeados/Dekiru Neko.png'
import Handyman from '../images/pamp-nomeados/Handyman.png'
import Pokemon from '../images/pamp-nomeados/Pokémon Concierge.png'
import ApothecaryDiaries from '../images/pamp-nomeados/The Apothecary Diaries.png'
import BokutoRoboko from '../images/pamp-nomeados/Boku to Roboko.png'
import Mashle from '../images/pamp-nomeados/Mashle.png'
import SpyxFamily from '../images/pamp-nomeados/Spy x Family.png'
import UnderNinja from '../images/pamp-nomeados/Under Ninja.png'
import Bluelock from '../images/pamp-nomeados/Blue Lock.png'
import KenganAshura from '../images/pamp-nomeados/Kengan Ashura.png'
import MouIppon from '../images/pamp-nomeados/Mou Ippon.png'
import Overtake from '../images/pamp-nomeados/Overtake.png'
import Tsurune from '../images/pamp-nomeados/Tsurune.png'
import AinoIdenshi from '../images/pamp-nomeados/Ai no Idenshi.png'
import Pluto from '../images/pamp-nomeados/Pluto.png'
import Tengoku from '../images/pamp-nomeados/Tengoku.png'
import MyHomeHero from '../images/pamp-nomeados/My Home Hero.png'
import UndeadGirl from '../images/pamp-nomeados/Undead Girl.png'
import BungouStrayDogs from '../images/pamp-nomeados/Bungou Stray Dogs.png'

/**Personagens */
import Zom100Akira from '../images/pamp-nomeados/Akira - Zom 100.png'
import SpyAnya from '../images/pamp-nomeados/Anya - Spy x Family.png'
import VinlandCanute from '../images/pamp-nomeados/Canute - Vinland Saga.png'
import DaigoBombeiro from '../images/pamp-nomeados/Daigo - Megumi no Daigo.png'
import FrierenElfGirl from '../images/pamp-nomeados/Frieren - Sousou no Frieren.png'
import PlutoGesicht from '../images/pamp-nomeados/Gesicht - Pluto.png'
import OookuIemitsu from '../images/pamp-nomeados/Iemitsu - Oooku.png'
import OnePieceKaido from '../images/pamp-nomeados/Kaidou - One Piece.png'
import LinkClickLiTianchen from '../images/pamp-nomeados/Li Tianchen - Link Click.png'
import jjkMahito from '../images/pamp-nomeados/Mahito - Jujutsu Kaisen.png'
import apothecaryMaomao from '../images/pamp-nomeados/Maomao - The Apothecary Diaries.png'
import TrigunMillionKnives from '../images/pamp-nomeados/Millions Knives - Trigun.png'
import LoaferMitsumi from '../images/pamp-nomeados/Mitsumi - Skip to Loafer.png'
import jjkNanami from '../images/pamp-nomeados/Nanami - Jujutsu Kaisen.png'
import PilgrimRamona from '../images/pamp-nomeados/Ramona - Scott Pilgrim.png'
import BungouRanpo from '../images/pamp-nomeados/Ranpo - Bungou Stray Dogs.png'
import DrStoneSenku from '../images/pamp-nomeados/Senku - Dr. Stone.png'
import jjkSukuna from '../images/pamp-nomeados/Sukuna - Jujutsu Kaisen.png'
import VinlandThorfinn from '../images/pamp-nomeados/Thorfinn - Vinland Saga.png'
import jjkTodo from '../images/pamp-nomeados/Todo - Jujutsu Kaisen.png'
import trigunVash from '../images/pamp-nomeados/Vash - Trigun.png'

/**OP & ED */
import JJKED1 from '../images/pamp-nomeados/Akari - Jujutsu Kaisen.png'
import JJKOP1 from '../images/pamp-nomeados/Ao no Sumika - Jujutsu Kaisen.png'
import ShangriLaOP from '../images/pamp-nomeados/Broken Games - Shangri-la.png'
import FrierenED from '../images/pamp-nomeados/Anytime Anywhere - Frieren.png'
import Zom100ED from '../images/pamp-nomeados/Happiness of the dead - Zom 100.png'
import OshiNoKoOP from '../images/pamp-nomeados/Idol - Oshi no Ko.png'
import TengokuOP from '../images/pamp-nomeados/Innocent - Tengoku.png'
import NekoED from '../images/pamp-nomeados/Night Before the Destruction - Dekiru Neko.png'
import VinlandOP from '../images/pamp-nomeados/Paradox - Vinland Saga.png'
import OnePieceED from '../images/pamp-nomeados/Raise - One Piece.png'
import Zom100OP from '../images/pamp-nomeados/Song of the Dead - Zom 100.png'
import DrStoneEd from '../images/pamp-nomeados/Suki no Shinayo - Dr Stone.png'
import LinkClickED from '../images/pamp-nomeados/The Tides - Link Click.png'
import SpyEd from '../images/pamp-nomeados/Todome no Ichigeki - Spy x Family.png'
import LinkClickOP from '../images/pamp-nomeados/Vortex - Link Click.png'
import JigokurakoOP from "../images/pamp-nomeados/Work - Hell's Paradise.png"

/**Manga **/
import MeninaDoOutroLado from '../images/pamp-nomeados/A menina que veio do outro lado.png'
import AtSummersEnd from '../images/pamp-nomeados/At summers end.png'
import BloomingLove from '../images/pamp-nomeados/Blooming Love.png'
import Bookhead from '../images/pamp-nomeados/bookhead.png'
import BugEgo from '../images/pamp-nomeados/bug ego.png'
import Dandadan from '../images/pamp-nomeados/DANDADAN.png'
import GreenGreen from '../images/pamp-nomeados/Green Green.png'
import HappyHappy from '../images/pamp-nomeados/happy happy.png'
import HelterSkelter from '../images/pamp-nomeados/Helter Skelter.png'
import HotaruNoYomeiri from '../images/pamp-nomeados/Hotaru no Yomeiri.png'
import JJKManga from '../images/pamp-nomeados/JUJUTSU KAISEN.png'
import Kagurabachi from '../images/pamp-nomeados/Kagurabachi.png'
import Kindergarten from '../images/pamp-nomeados/KINDERGARTEN.png'
import Medalist from '../images/pamp-nomeados/MEDALIST.png'
import Mujina from '../images/pamp-nomeados/Mujina.png'
import NarutoMinato from '../images/pamp-nomeados/naruto gaiden.png'
import PrecoDesonra from '../images/pamp-nomeados/O Preço da Desonra.png'
import OnePieceManga from '../images/pamp-nomeados/ONE PIECE MANGA.png'
import portaDoCeu from '../images/pamp-nomeados/Porta do Céu.png'
import renren from '../images/pamp-nomeados/RENREN.png'
import sachisBook from '../images/pamp-nomeados/Sachis book.png'
import sakamotoDays from '../images/pamp-nomeados/SAKAMOTO DAYS.png'
import Shibatarian from '../images/pamp-nomeados/Shibatarian.png'
import ShouniRyouri from '../images/pamp-nomeados/Shouni Ryouri.png'
import showHaShoten from '../images/pamp-nomeados/SHOW-HA SHOTEN.png'
import sorryThankYou from '../images/pamp-nomeados/sorry thank you.png'
import Sunny from '../images/pamp-nomeados/Sunny.png'
import TerrorMontanha from '../images/pamp-nomeados/Terror na Montanha.png'

/**Personalidades */
import AnimeTalks from '../images/pamp-nomeados/Animetalks.png'
import ForaDaToca from '../images/pamp-nomeados/Fora da Toca.png'
import BokuNoOtakus from '../images/pamp-nomeados/BokuNoOtakus.png'
import Niponelson from '../images/pamp-nomeados/Niponelson04.png'
import Ojisan from '../images/pamp-nomeados/Ojisan Podcast.png'
import Podcastiti from '../images/pamp-nomeados/Podcastiti.png'
import Shoganai from '../images/pamp-nomeados/Shoganai.png'
import TugasDAnime from '../images/pamp-nomeados/TugasDAnime.png'
import Lau from '../images/pamp-nomeados/UmaLauPorKyoto.png'
import Zander from '../images/pamp-nomeados/ZanderSenpai.png'
import AnimeThePT from '../images/pamp-nomeados/ANIMETHEPT.png'
import GumZone from '../images/pamp-nomeados/GUMZONE.png'
import HoraDoNerd from '../images/pamp-nomeados/HORADONERD.png'
import Kaiser from '../images/pamp-nomeados/KAISER.png'
import MiguelTCG from '../images/pamp-nomeados/MIGUELTCG.png'
import Remedy from '../images/pamp-nomeados/REMEDY.png'
import Titi from '../images/pamp-nomeados/TITI.png'
import TocaDoLebre from '../images/pamp-nomeados/TOCADOLEBRE.png'


// import  from '../images/anime'

function Nominees() {
    return (
      <div className='app'>
        <Header/>
        {/* ------------------------ ACTION ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE AÇÃO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51019/Kimetsu_no_Yaiba__Katanakaji_no_Sato-hen?q=demon%20sla&cat=anime'>
                <img className='nominee-image' alt='' src={DemonSlayer}></img>
                </a>
              </div>
                <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51009/Jujutsu_Kaisen_2nd_Season?q=juju&cat=anime'>
                <img className='nominee-image' alt='' src={Jujutsu}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52347/Shangri-La_Frontier__Kusoge_Hunter_Kamige_ni_Idoman_to_su?q=shangr&cat=anime'>
                <img className='nominee-image' alt='' src={Shangrila}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51535/Shingeki_no_Kyojin__The_Final_Season_-_Kanketsu-hen'>
                <img className='nominee-image' alt='' src={Snk}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52093/Trigun_Stampede?q=trigun&cat=anime'>
                <img className='nominee-image' alt='' src={Trigun}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ ACTION ------------------------*/}

        {/* ------------------------ ADVENTURE ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE AVENTURA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/48549/Dr_Stone__New_World?q=dr&cat=anime'>
                <img className='nominee-image' alt='' src={DrStone}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52991/Sousou_no_Frieren?q=friere&cat=anime'>
                <img className='nominee-image' alt='' src={Frieren}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52347/Shangri-La_Frontier__Kusoge_Hunter_Kamige_ni_Idoman_to_su?q=shangr&cat=anime'>
                <img className='nominee-image' alt='' src={Shangrila}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53393/Tengoku_Daimakyou?q=tengoku&cat=anime'>
                <img className='nominee-image' alt='' src={Tengoku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54112/Zom_100__Zombie_ni_Naru_made_ni_Shitai_100_no_Koto?q=zom-1&cat=anime'>
                <img className='nominee-image' alt='' src={Zom100}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ ADVENTURE ------------------------*/}

        {/* ------------------------ ROMANCE ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE ROMANCE</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/50796/Kimi_wa_Houkago_Insomnia?q=INSOM&cat=anime'>
                <img className='nominee-image' alt='' src={Insomniacs}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51552/Watashi_no_Shiawase_na_Kekkon?q=my%20happy&cat=anime'>
                <img className='nominee-image' alt='' src={MyHappyMarriage}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://anilist.co/anime/170206/Scott-Pilgrim-Takes-Off/'>
                <img className='nominee-image' alt='' src={ScottPilgrim}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/50416/Skip_to_Loafer?q=skip%20&cat=anime'>
                <img className='nominee-image' alt='' src={SkiptoLoafer}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53126/Yamada-kun_to_Lv999_no_Koi_wo_Suru?q=yamada&cat=anime'>
                <img className='nominee-image' alt='' src={Yamada}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ ROMANCE ------------------------*/}

        {/* ------------------------ DRAMA ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE DRAMA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49413/Shiguang_Dailiren_II?q=link&cat=anime'>
                <img className='nominee-image' alt='' src={LinkClick}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53848/Megumi_no_Daigo__Kyuukoku_no_Orange?q=daigo&cat=anime'>
                <img className='nominee-image' alt='' src={MeguminoDaigo}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54850/Oooku?q=oooku&cat=anime'>
                <img className='nominee-image' alt='' src={Ooku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52034/Oshi_no_Ko?q=oshi%20no&cat=anime'>
                <img className='nominee-image' alt='' src={OshinoKo}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49387/Vinland_Saga_Season_2?q=vinland&cat=anime'>
                <img className='nominee-image' alt='' src={Vinland}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ DRAMA ------------------------*/}

        {/* ------------------------ SLICE OF LIFE ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE SLICE OF LIFE</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51916/Dekiru_Neko_wa_Kyou_mo_Yuuutsu?q=dekiru&cat=anime'>
                <img className='nominee-image' alt='' src={DekiruNeko}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/50854/Benriya_Saitou-san_Isekai_ni_Iku?q=handy&cat=anime'>
                <img className='nominee-image' alt='' src={Handyman}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54633/Pokemon_Concierge?q=pokemon%20conci&cat=anime'>
                <img className='nominee-image' alt='' src={Pokemon}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/50416/Skip_to_Loafer?q=skip%20&cat=anime'>
                <img className='nominee-image' alt='' src={SkiptoLoafer}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54492/Kusuriya_no_Hitorigoto?q=apothe&cat=anime'>
                <img className='nominee-image' alt='' src={ApothecaryDiaries}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ SLICE OF LIFE ------------------------*/}

        {/* ------------------------ COMEDY ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE COMÉDIA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51940/Boku_to_Roboko?q=roboko&cat=anime'>
                <img className='nominee-image' alt='' src={BokutoRoboko}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52211/Mashle?q=mashle&cat=anime'>
                <img className='nominee-image' alt='' src={Mashle}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://anilist.co/anime/170206/Scott-Pilgrim-Takes-Off/'>
                <img className='nominee-image' alt='' src={ScottPilgrim}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53887/Spy_x_Family_Season_2?q=spy&cat=anime'>
                <img className='nominee-image' alt='' src={SpyxFamily}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49766/Under_Ninja?q=under%20ninja&cat=anime'>
                <img className='nominee-image' alt='' src={UnderNinja}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ COMEDY ------------------------*/}

        {/* ------------------------ SPORT ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE DESPORTO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49596/Blue_Lock?q=blue%20lock&cat=anime'>
                <img className='nominee-image' alt='' src={Bluelock}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51369/Kengan_Ashura_Season_2?q=kengan&cat=anime'>
                <img className='nominee-image' alt='' src={KenganAshura}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49376/Mou_Ippon?q=ippon&cat=anime'>
                <img className='nominee-image' alt='' src={MouIppon}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54301/Overtake?q=overtake&cat=anime'>
                <img className='nominee-image' alt='' src={Overtake}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52826/Tsurune__Tsunagari_no_Issha?q=tsurune&cat=anime'>
                <img className='nominee-image' alt='' src={Tsurune}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ SPORT ------------------------*/}
        
        {/* ------------------------ SCI-FI ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE SCI-FI</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/48549/Dr_Stone__New_World?q=dr&cat=anime'>
                <img className='nominee-image' alt='' src={DrStone}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52093/Trigun_Stampede?q=trigun&cat=anime'>
                <img className='nominee-image' alt='' src={Trigun}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53393/Tengoku_Daimakyou?q=tengoku&cat=anime'>
                <img className='nominee-image' alt='' src={Tengoku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53787/AI_no_Idenshi?q=ai%20no%20ide&cat=anime'>
                <img className='nominee-image' alt='' src={AinoIdenshi}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/35737/Pluto?q=pluto&cat=anime'>
                <img className='nominee-image' alt='' src={Pluto}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ SCI-FI------------------------*/}

        {/* ------------------------ PSYCHOLOGICAL/MISTERY ------------------------*/}
        <div className='nominee-title'>MELHOR ANIME DE PSICOLÓGICO E MISTÉRIO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49413/Shiguang_Dailiren_II?q=link&cat=anime'>
                <img className='nominee-image' alt='' src={LinkClick}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49413/Shiguang_Dailiren_II?q=link&cat=anime'>
                <img className='nominee-image' alt='' src={Pluto}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53393/Tengoku_Daimakyou?q=tengoku&cat=anime'>
                <img className='nominee-image' alt='' src={Tengoku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54790/Undead_Girl_Murder_Farce?q=undead%20girl&cat=anime'>
                <img className='nominee-image' alt='' src={UndeadGirl}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52092/My_Home_Hero?q=my%20home%20hero&cat=anime'>
                <img className='nominee-image' alt='' src={MyHomeHero}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ PSYCHOLOGICAL/MISTERY ------------------------*/}

        {/* ------------------------ ANIMATION ------------------------*/}
        <div className='nominee-title'>MELHOR ANIMAÇÃO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53393/Tengoku_Daimakyou?q=tengoku&cat=anime'>
                <img className='nominee-image' alt='' src={Tengoku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52093/Trigun_Stampede?q=trigun&cat=anime'>
                <img className='nominee-image' alt='' src={Trigun}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51009/Jujutsu_Kaisen_2nd_Season?q=jujutsu&cat=anime'>
                <img className='nominee-image' alt='' src={Jujutsu}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54492/Kusuriya_no_Hitorigoto?q=apothecar&cat=anime'>
                <img className='nominee-image' alt='' src={ApothecaryDiaries}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49387/Vinland_Saga_Season_2?q=vinland&cat=anime'>
                <img className='nominee-image' alt='' src={Vinland}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51019/Kimetsu_no_Yaiba__Katanakaji_no_Sato-hen?q=demon%20slayer&cat=anime'>
                <img className='nominee-image' alt='' src={DemonSlayer}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ ANIMATION ------------------------*/}

        {/* ------------------------ DIRECTION ------------------------*/}
        <div className='nominee-title'>MELHOR DIREÇÃO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53393/Tengoku_Daimakyou'>
                <img className='nominee-image' alt='' src={Tengoku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://anilist.co/anime/170206/Scott-Pilgrim-Takes-Off/'>
                <img className='nominee-image' alt='' src={ScottPilgrim}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51009/Jujutsu_Kaisen_2nd_Season?q=juju&cat=anime'>
                <img className='nominee-image' alt='' src={Jujutsu}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54112/Zom_100__Zombie_ni_Naru_made_ni_Shitai_100_no_Koto?q=zom%20100&cat=anime'>
                <img className='nominee-image' alt='' src={Zom100}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54112/Zom_100__Zombie_ni_Naru_made_ni_Shitai_100_no_Koto?q=zom%20100&cat=anime'>
                <img className='nominee-image' alt='' src={Vinland}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49413/Shiguang_Dailiren_II?q=link%20cl&cat=anime'>
                <img className='nominee-image' alt='' src={LinkClick}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ DIRECTION ------------------------*/}

        {/* ------------------------ OPENING ------------------------*/}
        <div className='nominee-title'>MELHOR OPENING</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/RS94j08yIHs?si=iYbC1FoKJExvui4p'>
                <img className='nominee-image' alt='' src={JigokurakoOP}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/mm-BT_PreFo?si=M3O4XAx2yeoLL8TN'>
                <img className='nominee-image' alt='' src={VinlandOP}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/KUBqBkGrmso?si=paw3UhAxJzjMDy-1'>
                <img className='nominee-image' alt='' src={LinkClickOP}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/PgBvV9ofjmA?si=XMJj_sMvOkqvbr-5'>
                <img className='nominee-image' alt='' src={OshiNoKoOP}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/o4U24bkGB0s?si=IQjVNKXwJLMPO1YE'>
                <img className='nominee-image' alt='' src={Zom100OP}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/gcgKUcJKxIs?si=amx8Nu8FfRG5fGzF'>
                <img className='nominee-image' alt='' src={JJKOP1}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/4hUx_N8QIYA?si=ecE-djk0VWgPV6P0'>
                <img className='nominee-image' alt='' src={ShangriLaOP}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/GuAcdIqcanA?si=4UwJthk_GvqqVWgh'>
                <img className='nominee-image' alt='' src={TengokuOP}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ OPENING ------------------------*/}

        {/* ------------------------ ENDING ------------------------*/}
        <div className='nominee-title'>MELHOR ENDING</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/evby-0YWQGU?si=APOHZXe7ZzoOLv4Y'>
                <img className='nominee-image' alt='' src={NekoED}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/uOlDbKkvmhk?si=LmVfLaD-ZvhNzPH5'>
                <img className='nominee-image' alt='' src={DrStoneEd}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/7pmd0kt3FOs?si=VKxQ10teu5Vcy9D4'>
                <img className='nominee-image' alt='' src={FrierenED}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/fc2g6MpDnIw?si=MjfUYVyVWNsfSRiu'>
                <img className='nominee-image' alt='' src={LinkClickED}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/ZSLmP-af8W0?si=EzUnGS8S-HHbf9Op'>
                <img className='nominee-image' alt='' src={JJKED1}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/stXbvYGvrxA?si=d2wbXmnwjY3l3FxS'>
                <img className='nominee-image' alt='' src={OnePieceED}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/4Ql6YwCTAko?si=Or5LzUE90B480z39'>
                <img className='nominee-image' alt='' src={SpyEd}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://youtu.be/C6ufOc-buj0?si=S3K44kT0IgRNkO3f'>
                <img className='nominee-image' alt='' src={Zom100ED}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ ENDING ------------------------*/}

        {/* ------------------------ SEQUELA ------------------------*/}
        <div className='nominee-title'>SEQUELA DO ANO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49387/Vinland_Saga_Season_2?q=vinland&cat=anime'>
                <img className='nominee-image' alt='' src={Vinland}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51009/Jujutsu_Kaisen_2nd_Season?q=juju&cat=anime'>
                <img className='nominee-image' alt='' src={Jujutsu}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/49413/Shiguang_Dailiren_II?q=link%20cli&cat=anime'>
                <img className='nominee-image' alt='' src={LinkClick}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53887/Spy_x_Family_Season_2?q=spy&cat=anime'>
                <img className='nominee-image' alt='' src={SpyxFamily}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51535/Shingeki_no_Kyojin__The_Final_Season_-_Kanketsu-hen?q=attack%20on%20titan%20final%20sea&cat=anime'>
                <img className='nominee-image' alt='' src={Snk}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/51019/Kimetsu_no_Yaiba__Katanakaji_no_Sato-hen?q=kimets&cat=anime'>
                <img className='nominee-image' alt='' src={DemonSlayer}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54898/Bungou_Stray_Dogs_5th_Season?q=bungou&cat=anime'>
                <img className='nominee-image' alt='' src={BungouStrayDogs}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/48549/Dr_Stone__New_World?q=dr&cat=anime'>
                <img className='nominee-image' alt='' src={DrStone}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ SEQUELA ------------------------*/}

        {/* ------------------------ ANIME 2023 ------------------------*/}
        <div className='nominee-title'>ANIME DO ANO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52991/Sousou_no_Frieren?q=frieren&cat=anime'>
                <img className='nominee-image' alt='' src={Frieren}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/53393/Tengoku_Daimakyou?q=tengoku&cat=anime'>
                <img className='nominee-image' alt='' src={Tengoku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52093/Trigun_Stampede?q=trigun&cat=anime'>
                <img className='nominee-image' alt='' src={Trigun}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54112/Zom_100__Zombie_ni_Naru_made_ni_Shitai_100_no_Koto?q=zom%20100&cat=anime'>
                <img className='nominee-image' alt='' src={Zom100}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52347/Shangri-La_Frontier__Kusoge_Hunter_Kamige_ni_Idoman_to_su?q=shangri&cat=anime'>
                <img className='nominee-image' alt='' src={Shangrila}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/35737/Pluto?q=pluto&cat=anime'>
                <img className='nominee-image' alt='' src={Pluto}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54492/Kusuriya_no_Hitorigoto?q=apothec&cat=anime'>
                <img className='nominee-image' alt='' src={ApothecaryDiaries}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://anilist.co/anime/170206/Scott-Pilgrim-Takes-Off'>
                <img className='nominee-image' alt='' src={ScottPilgrim}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/54850/Oooku?q=oooku&cat=anime'>
                <img className='nominee-image' alt='' src={Ooku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/anime/52034/Oshi_no_Ko?q=oshi&cat=anime'>
                <img className='nominee-image' alt='' src={OshinoKo}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ ANIME 2023 ------------------------*/}
        
        {/* ------------------------ BEST CHARACTER ------------------------*/}
        <div className='nominee-title'>MELHOR PROTAGONISTA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={VinlandThorfinn}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={apothecaryMaomao}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={DrStoneSenku}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={trigunVash}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={PilgrimRamona}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={PlutoGesicht}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST CHARACTER ------------------------*/}

        {/* ------------------------ BEST ANTAGONIST ------------------------*/}
        <div className='nominee-title'>MELHOR ANTAGONISTA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={VinlandCanute}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={jjkMahito}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={jjkSukuna}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={TrigunMillionKnives}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={LinkClickLiTianchen}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={OnePieceKaido}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST ANTAGONIST ------------------------*/}

        {/* ------------------------ BEST MALE CHARACTER ------------------------*/}
        <div className='nominee-title'>MELHOR PERSONAGEM MASCULINA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={VinlandThorfinn}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={jjkNanami}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={DaigoBombeiro}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={Zom100Akira}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={BungouRanpo}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={jjkTodo}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST MALE CHARACTER ------------------------*/}

        {/* ------------------------ BEST FEMALE CHARACTER ------------------------*/}
        <div className='nominee-title'>MELHOR PERSONAGEM FEMININA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={FrierenElfGirl}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={apothecaryMaomao}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={PilgrimRamona}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={OookuIemitsu}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={LoaferMitsumi}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={SpyAnya}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST FEMALE CHARACTER ------------------------*/}

        {/* ------------------------ MELHOR LANÇAMENTO DE "MANGA" EM PORTUGAL ------------------------*/}
        <div className='nominee-title'>MELHOR LANÇAMENTO DE "MANGA" EM PORTUGAL</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={MeninaDoOutroLado}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={HelterSkelter}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={Sunny}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={PrecoDesonra}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={TerrorMontanha}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer">
                <img className='nominee-image' alt='' src={portaDoCeu}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ MELHOR LANÇAMENTO DE "MANGA" EM PORTUGAL ------------------------*/}

        {/* ------------------------ BEST MANGA DEBUT ------------------------*/}
        <div className='nominee-title'>MELHOR ESTREIA MANGA</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/164644/Natsu_no_Shuuten?q=at%20summer%27s%20end&cat=manga'>
                <img className='nominee-image' alt='' src={AtSummersEnd}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/164790/Green_Green_Greens?q=green&cat=manga'>
                <img className='nominee-image' alt='' src={GreenGreen}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/158779/Hanninmae_no_Koibito?q=blooming&cat=manga'>
                <img className='nominee-image' alt='' src={BloomingLove}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/162479/Kagurabachi?q=kagura&cat=manga'>
                <img className='nominee-image' alt='' src={Kagurabachi}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/154679/Hotaru_no_Yomeiri?q=hotaru%20no%20yom&cat=manga'>
                <img className='nominee-image' alt='' src={HotaruNoYomeiri}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/156211/Mujina_into_the_Deep?q=mujina&cat=manga'>
                <img className='nominee-image' alt='' src={Mujina}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/160366/Sachi-roku__Sachi_no_Mokushiroku'>
                <img className='nominee-image' alt='' src={sachisBook}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/157543/Shibatarian?q=shibatari&cat=manga'>
                <img className='nominee-image' alt='' src={Shibatarian}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST MANGA DEBUT ------------------------*/}

        {/* ------------------------ BEST MANGA 2023 ------------------------*/}
        <div className='nominee-title'>MELHOR MANGA 2023</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/135496/Dandadan?q=dandadan&cat=manga'>
                <img className='nominee-image' alt='' src={Dandadan}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/151604/Youchien_Wars?q=kinderga&cat=manga'>
                <img className='nominee-image' alt='' src={Kindergarten}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/113138/Jujutsu_Kaisen?q=jujuts&cat=manga'>
                <img className='nominee-image' alt='' src={JJKManga}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/13/One_Piece'>
                <img className='nominee-image' alt='' src={OnePieceManga}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/121504/Yubisaki_to_Renren?q=renren&cat=manga'>
                <img className='nominee-image' alt='' src={renren}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/121504/Yubisaki_to_Renren?q=renren&cat=manga'>
                <img className='nominee-image' alt='' src={Medalist}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/140868/Shouha_Shouten'>
                <img className='nominee-image' alt='' src={showHaShoten}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/131334/Sakamoto_Days?q=sakamoto&cat=manga'>
                <img className='nominee-image' alt='' src={sakamotoDays}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST MANGA 2023 ------------------------*/}

        {/* ------------------------ BEST MANGA ONE-SHOT 2023 ------------------------*/}
        <div className='nominee-title'>MELHOR ONE-SHOT 2023</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://anilist.co/manga/167268/Bookhead'>
                <img className='nominee-image' alt='' src={Bookhead}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/158358/Bug_Ego?q=bug%20ego&cat=manga'>
                <img className='nominee-image' alt='' src={BugEgo}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/155637/Happy_Happy_Endorphin'>
                <img className='nominee-image' alt='' src={HappyHappy}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/160710/Naruto_Gaiden__Uzu_no_Naka_no_Tsumujikaze?q=naruto%20gai&cat=manga'>
                <img className='nominee-image' alt='' src={NarutoMinato}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://myanimelist.net/manga/163229/Shouni_Ryouri?q=shouni%20ryouri&cat=manga'>
                <img className='nominee-image' alt='' src={ShouniRyouri}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://anilist.co/manga/163162/Arigato'>
                <img className='nominee-image' alt='' src={sorryThankYou}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST MANGA ONE-SHOT 2023 ------------------------*/}

        {/* ------------------------ BEST INSTAGRAM ------------------------*/}
        <div className='nominee-title'>PERSONALIDADE DO ANO - INSTAGRAM</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/boku_no_otakus/'>
                <img className='nominee-image' alt='' src={BokuNoOtakus}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/niponelson04/'>
                <img className='nominee-image' alt='' src={Niponelson}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/tugasd.anime/'>
                <img className='nominee-image' alt='' src={TugasDAnime}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/uma_lau_por_kyoto/'>
                <img className='nominee-image' alt='' src={Lau}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/zander_senpai/'>
                <img className='nominee-image' alt='' src={Zander}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST INSTAGRAM ------------------------*/}

        {/* ------------------------ BEST YOUTUBE ------------------------*/}
        <div className='nominee-title'>PERSONALIDADE DO ANO - YOUTUBE</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@KaiserFGO'>
                <img className='nominee-image' alt='' src={Kaiser}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@HoraDoNerd_Anime'>
                <img className='nominee-image' alt='' src={HoraDoNerd}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@animethept1470'>
                <img className='nominee-image' alt='' src={AnimeThePT}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@gumzoneYT'>
                <img className='nominee-image' alt='' src={GumZone}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@TocadoLebre'>
                <img className='nominee-image' alt='' src={TocaDoLebre}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST YOUTUBE ------------------------*/}

        {/* ------------------------ BEST TWITCH ------------------------*/}
        <div className='nominee-title'>PERSONALIDADE DO ANO - TWITCH</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.twitch.tv/migueltcgbreaks'>
                <img className='nominee-image' alt='' src={MiguelTCG}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.twitch.tv/titi_official/'>
                <img className='nominee-image' alt='' src={Titi}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.twitch.tv/theremedylive'>
                <img className='nominee-image' alt='' src={Remedy}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.twitch.tv/niponelson04'>
                <img className='nominee-image' alt='' src={Niponelson}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.twitch.tv/kaiserfgo'>
                <img className='nominee-image' alt='' src={Kaiser}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST TWITCH ------------------------*/}

        {/* ------------------------ BEST PODCAST ------------------------*/}
        <div className='nominee-title'>PODCAST DO ANO</div>
        <div className='box'>
            <div className='nominee-main-container'>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/watch?v=put19YwwDYk&list=PLHIW7oDQtkQyD4Eu__xiALxVaGCfpPdyN'>
                <img className='nominee-image' alt='' src={AnimeTalks}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://open.spotify.com/show/0v6s0qkNfFXNpj4ejPuhpy'>
                <img className='nominee-image' alt='' src={ForaDaToca}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://open.spotify.com/show/46T3VYI8NB6hy95fAf0qUz'>
                <img className='nominee-image' alt='' src={Ojisan}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://www.twitch.tv/collections/rkSfBcvCcRcCrQ'>
                <img className='nominee-image' alt='' src={Podcastiti}></img>
                </a>
              </div>
              <div className='nominee-container'>
                <a target="_blank" rel="noreferrer" href='https://open.spotify.com/show/1VNOiNlAgxpunSIW0FOEpH'>
                <img className='nominee-image' alt='' src={Shoganai}></img>
                </a>
              </div>
          </div>
        </div>
        {/* ------------------------ BEST PODCAST ------------------------*/}
      </div>  
    );
  }
  
  export default Nominees