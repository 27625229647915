import Header from './components/Header'
import Main from './components/Main'
import Footer from './components/Footer'
import '../src/styles/globals.css'

function App() {

  return (
    <div className='app'> 
      {/* Routes to be added here */}
      <Header/>
      <Main/>
    </div>
  );
}

export default App;